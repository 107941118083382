<template>
  <transition name="el-zoom-in-center">
    <div class="SNOWS-preview-main">
      <div class="SNOWS-common-page-header">
        <el-page-header :content="!dataForm.table ? '新建表名' : '编辑表名'" @back="goBack" />
        <div class="options">
          <el-button :loading="btnLoading" type="primary" @click="dataFormSubmit()">
            确定
          </el-button>
          <el-button @click="goBack()">取消</el-button>
        </div>
      </div>
      <div class="main">
        <el-form ref="dataForm" :model="dataForm" :rules="dataRule" class="mt-20" label-width="80px">
          <el-row>
            <el-col :span="8">
              <el-form-item label="表名称" prop="newTable">
                <el-input v-model="dataForm.newTable" :disabled="dataForm.table?true:false" maxlength="50" placeholder="请输入表名称" style="width: 100%;"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="表说明" prop="tableName">
                <el-input v-model="dataForm.tableName" placeholder="请输入表说明" style="width: 100%;"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="SNOWS-common-title" style="padding:0 10px;">
          <h2>字段设置</h2>
          <div class="options">
            <el-dropdown :disabled="!fieldList.length">
              <el-button :disabled="!fieldList.length">
                常用字段<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="item in fieldList" :key="item.id"
                                  @click.native="addHandle(item)">{{ item.filedName }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <el-row :gutter="16" class="SNOWS-common-search-box">
          <el-form @submit.native.prevent>
              <el-col :span="6">
                <el-form-item label="列名">
                  <el-input v-model="query.filedName" placeholder="请输入列名" @input="search"></el-input>
                </el-form-item>
              </el-col>
          </el-form>
        </el-row>
        <el-table v-if="refreshTable" ref="dragTable" v-loading="listLoading" :data="list"
                  :row-key="row=>row.index" size='mini'>
          <el-table-column align="center" label="序号" type="index" width="50" />
          <el-table-column label="列名" prop="filedName">
            <template slot-scope="scope">
              <p v-if="scope.row.disabled">{{ scope.row.filedName }}</p>
              <el-input v-else v-model="scope.row.filedName" maxlength="50" placeholder="请输入列名" />
            </template>
          </el-table-column>
          <el-table-column label="类型" prop="dataType">
            <template slot-scope="scope">
              <p v-if="scope.row.disabled">{{ scope.row.dataType }}</p>
              <el-select v-else v-model="scope.row.dataType" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label"
                           :value="item.value" />
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="长度" prop="length">
            <template slot-scope="scope">
              <p v-if="scope.row.disabled">{{ scope.row.length }}</p>
              <el-input v-else v-model="scope.row.length" :disabled="scope.row.dataType!=='varchar'&&scope.row.dataType!=='decimal'"
                        placeholder="请输入长度" />
            </template>
          </el-table-column>
          <el-table-column align="center" label="是否主键" prop="isMainKey" width="70">
            <template slot-scope="scope">
              <el-checkbox v-if="scope.row.disabled" :value='!!scope.row.isMainKey' />
              <el-checkbox v-else v-model="scope.row.isMainKey"
                           :false-label="false" :true-label="true" @change='changeKey($event,scope.row)' />
            </template>
          </el-table-column>
<!--          <el-table-column align="center" label="允许空" prop="isNull" width="60">
            <template slot-scope="scope">
              <el-checkbox v-if="scope.row.disabled" :value='!!scope.row.isNull' />
              <el-checkbox v-else v-model="scope.row.isNull" :false-label="false" :true-label="true" />
            </template>
          </el-table-column>-->
          <el-table-column label="说明" prop="comment">
            <template slot-scope="scope">
              <p v-if="scope.row.disabled">{{ scope.row.comment }}</p>
              <el-input v-else v-model="scope.row.comment" placeholder="请输入说明" />
            </template>
          </el-table-column>
          <el-table-column label="操作" width="50">
            <template slot-scope="scope">
              <el-button v-if="!scope.row.disabled" class="SNOWS-table-delBtn" size="mini"
                         type="text" @click="handleDel(scope.$index,scope.row)">删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="table-actions" @click="addHandle()">
          <el-button icon="el-icon-plus" type="text">新建字段</el-button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Sortable from 'sortablejs'
import {addTable,updateTable, DataModelUpdate, editTable} from '@/api/system/dataModel'
// import { getList } from '@/api/systemData/commonFields'
export default {
  data() {
    return {
      dataForm: {
        table: '',
        tableName: '',
        newTable: ''
      },
      dataRule: {
        newTable: [
          {required: true, message: '表名称不能为空', trigger: 'blur'},
          {pattern: /(^_([A-Za-z0-9]_?)*$)|(^[A-Za-z](_?[A-Za-z0-9])*_?$)/, message: '请输入正确的表名称', trigger: ['blur']},
        ],
        tableName: [
          {required: true, message: '表说明不能为空', trigger: 'blur'}
        ]
      },
      query: {
        filedName: ''
      },
      list: [],
      listBak: [],
      oldlist:[],
      fieldList: [],
      listLoading: false,
      btnLoading: false,
      refreshTable: true,
      sortable: null,
      dataBase: '',
      showAll: false,
      options: [
        {label: '字符串', value: 'varchar'},
        {label: '整型', value: 'int'},
        {label: '日期时间', value: 'datetime'},
        {label: '浮点', value: 'decimal'},
        {label: '长整型', value: 'bigint'},
        {label: '文本', value: 'text'}
      ]
    }
  },
  methods: {
    goBack() {
      this.$emit('close')
    },
    getFieldList() {
      getList().then(res => {
        this.fieldList = res.data.list
      })
    },
    search(e) {
      if(e == '') {
        this.list = this.listBak
        return
      }
      this.list = this.listBak.filter(item => item.filedName.indexOf(e) > -1);
    },
    reset() {
      this.query.filedName = '';
      editTable(this.dataBase, {
        tableName: this.dataForm.table
      }).then(res => {
        let data = res.data.data.map((o, i) => ({index: i, ...o}))
        data.forEach((item) => {
          if (item.dataType != 'varchar') {
            item.length = '';
          }
        })
        this.list = data;
        this.listLoading = false
        this.$set(this.dataForm, 'newTable', this.dataForm.table)
        this.$set(this.dataForm, 'tableName', this.dataForm.table_name)
      })
    },
    init(dataBase, table) {
      this.$refs['dataForm'].resetFields()
      this.dataForm.table = table.table_name || ''
      this.dataBase = dataBase
      this.listLoading = true
      // this.getFieldList()
      this.$nextTick(() => {
        if (this.dataForm.table) {
          editTable(dataBase, {
            tableName: this.dataForm.table
          }).then(res => {
            let data = res.data.data.map((o, i) => ({index: i, ...o}))
            data.forEach((item) => {
              if (item.dataType != 'varchar') {
                item.length = '';
              }
            })
            this.list = data;
            this.listBak = data;
            // this.oldlist = data.concat();
            this.oldlist = JSON.parse(JSON.stringify(data));
            this.listLoading = false
            // this.dataForm = res.data.tableInfo
            // const hasTableData = res.data.hasTableData || false
            this.$set(this.dataForm, 'newTable', this.dataForm.table)
            this.$set(this.dataForm, 'tableName', table.table_comment)
            // this.list = res.data.tableFieldList.map((o, i) => ({ index: i, ...o }))
            // for (let index = 0; index < this.list.length; index++) {
            //   const element = this.list[index];
            //   if (hasTableData) element.disabled = true
            // }
            // this.listLoading = false
            // this.$nextTick(() => {
            //   if (!hasTableData) this.setSort()
            // })
          })
        } else {
          this.dataForm.newTable = table || ''
          this.listLoading = false
          this.list = []
        }
      })
    },
    setSort() {
      const el = this.$refs.dragTable.$el.querySelectorAll('.el-table__body-wrapper > table > tbody')[0]
      this.sortable = Sortable.create(el, {
        ghostClass: 'sortable-ghost', // Class name for the drop placeholder,
        setData: function (dataTransfer) {
          // to avoid Firefox bug
          // Detail see : https://github.com/RubaXa/Sortable/issues/1012
          dataTransfer.setData('Text', '')
        },
        onEnd: evt => {
          const targetRow = this.list.splice(evt.oldIndex, 1)[0]
          this.list.splice(evt.newIndex, 0, targetRow)
        },
        handle: '.drag-handler',
      })
    },
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          if (!this.list.length) {
            this.$message({
              message: '请至少添加一个字段',
              type: 'error',
              duration: 1000
            })
            return
          }
          if (!this.exist()) return
          let boo = this.list.some(o => o.isMainKey === true)
          if (!boo) {
            this.$message({
              message: '请选择一个字段作为主键',
              type: 'error',
              duration: 1000
            })
            return
          }
          this.btnLoading = true

          // let tableFieldList = this.list.filter(o => !o.disabled)
          // if (tableFieldList.length && tableFieldList.length != this.list.length) {
          //   let query = {
          //     tableFieldList,
          //     tableInfo: this.dataForm
          //   }
          //   addTable(this.dataBase, query).then((res) => {
          //     this.$message({
          //       message: res.msg,
          //       type: 'success',
          //       duration: 1500,
          //       onClose: () => {
          //         this.visible = false
          //         this.btnLoading = false
          //         this.$emit('close', true)
          //       }
          //     })
          //   }).catch(() => {
          //     this.btnLoading = false
          //   })
          //   return
          // }

          // let query = {
          //   tableName: this.dataForm.newTable,
          //   tableComment: this.dataForm.tableName,
          //   dbFiledsVOList: this.list
          // }
          // const formMethod = this.dataForm.table ? updateTable : addTable
          // formMethod(this.dataBase, query).then((res) => {
          //   this.$message({
          //     message: res.data.data.msg,
          //     type: 'success',
          //     duration: 1500,
          //     onClose: () => {
          //       this.visible = false
          //       this.btnLoading = false
          //       this.$emit('close', true)
          //       this.$emit('gridHeadEmpty')
          //     }
          //   })
          // }).catch(() => {
          //   this.btnLoading = false
          // })
          this.list.forEach((item) => {
            if (item.dataType !== 'varchar') {
              item.length = '';
            }
          })
          if (this.dataForm.table) {
            let query = {
              tableName: this.dataForm.newTable,
              tableComment: this.dataForm.tableName,
              oldList: this.oldlist,
              newList: this.list
            }
            updateTable(this.dataBase, query).then((res) => {
              this.$message({
                message: res.data.msg,
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.btnLoading = false
                  this.$emit('close', true)
                  this.$emit('gridHeadEmpty')
                }
              })
            }).catch(() => {
              this.btnLoading = false
            })
          } else {
            let query = {
              tableName: this.dataForm.newTable,
              tableComment: this.dataForm.tableName,
              dbFiledsVOList: this.list
            }
            addTable(this.dataBase, query).then((res) => {
              this.$message({
                message: res.data.msg,
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.btnLoading = false
                  this.$emit('close', true)
                  this.$emit('gridHeadEmpty')
                }
              })
            }).catch(() => {
              this.btnLoading = false
            })
          }
        }
      })
    },
    exist() {
      let isOk = true;
      //  遍历数组，判断非空
      for (let i = 0; i < this.list.length; i++) {
        const e = this.list[i];
        if (!e.filedName) {
          this.$message({
            showClose: true,
            message: `第${i + 1}行列名不能为空`,
            type: 'error',
            duration: 1000
          });
          isOk = false
          break
        }
        let reg = /(^_([a-zA-Z0-9]_?)*$)|(^[a-zA-Z](_?[a-zA-Z0-9])*_?$)/
        if (!reg.test(e.filedName)) {
          this.$message({
            showClose: true,
            message: `第${i + 1}行列名格式错误，请重新输入`,
            type: 'error',
            duration: 1000
          });
          isOk = false
          break
        }
        let num = this.list.filter(o => o.filedName == e.filedName)
        if (num.length > 1) {
          this.$message({
            showClose: true,
            message: `第${i + 1}行列名'${e.filedName}'已重复`,
            type: 'error',
            duration: 1000
          });
          isOk = false
          break
        }
        if (!e.comment) {
          this.$message({
            showClose: true,
            message: `第${i + 1}行说明不能为空`,
            type: 'error',
            duration: 1000
          });
          isOk = false
          break
        }
      }
      return isOk;
    },
    changeKey(val, row) {
      row.isNull = false
      row.dataType = 'varchar'
      row.length = '50'
      if (!val) return
      for (let i = 0; i < this.list.length; i++) {
        if (row.filedName != this.list[i].filedName) {
          this.$set(this.list[i], 'isMainKey', false)
        }
      }
    },
    handleDel(index, row) {
      this.list.splice(index, 1)
    },
    addHandle(row) {
      let index = this.list.length, item = {}
      if (!row) {
        item = {
          filedName: "", dataType: "varchar", length: 50, isNull: true, isMainKey: false, comment: "", index
        }
      } else {
        item = {
          filedName: row.filedName,
          dataType: row.dataType,
          length: row.length,
          isNull: row.isNull,
          comment: row.comment,
          index,
          isMainKey: false
        }
      }
      this.list.push(item)
    }
  }
}
</script>
<style lang="scss" scoped>
.main {
  padding: 10px;
}

.SNOWS-common-title h2 {
  line-height: inherit;
}
</style>
