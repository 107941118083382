<template>
  <div class="content">
    <head-layout :head-btn-options="headBtnOptions"
                 head-title="数据建模"
                 @head-remove="handleDelete"
                 @head-add-tabs="headAdd"></head-layout>
    <grid-head-layout ref="gridHeadLayout"
                      :search-columns="searchColumns"
                      @grid-head-search="gridHeadSearch"
                      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      :grid-row-btn="gridRowBtn"
      :table-data="tableData"
      :table-loading="tableLoading"
      :table-options="tableOptions"
      @row-edit="rowEdit"
      @row-remove="rowRemove"
      @row-open="rowOpen"
      @gird-handle-select-click="selectionChange"
    ></grid-layout>
    <!--新增/编辑-->
    <Form v-show="formVisible" ref="Form" @close="closeForm" />
    <!--打开数据-->
    <Preview v-show="showData" ref="preview" @close="closeData" />
  </div>
</template>
<script>
import Form from './Form'
import Preview from './Preview'
import GridLayout from "@/views/components/layout/grid-layout";
import {dataLinkList, dataModelList, deleteTable} from "@/api/system/dataModel";

export default {
  components: {Form, Preview,GridLayout},
  data() {
    return {
      page: {},
      tableLoading: false,
      tableData: [],
      tableDataBak:[],
      formVisible: false,
      showData: false,
      selectionList: [],
      dataBase:'',
      dataLink:[]
    }
  },
  computed: {
    table_name() {
      let table_names = this.selectionList.map(item => item.table_name);
      return table_names.join(",");
    },
    headBtnOptions() {
      return [
        {
          label: this.$t(`cip.cmn.btn.addBtn`),
          emit: "head-add-tabs",
          type: "button",
          icon: "",
          btnOptType: 'add',
        },
        {
          label: this.$t(`cip.cmn.btn.delBtn`),
          emit: "head-remove",
          type: "button",
          icon: "",
          btnOptType: 'remove',
        }
      ]
    },
    searchColumns() {
      return [
        {
          label: '表名',
          prop: "title",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: '请输入表名',
          type: 'input',
          change: (val)=> {
              this.tableData = this.tableDataBak.filter(e => e.table_name.indexOf(val.value) > -1)
              if(!val.value){
                this.tableData = this.tableDataBak
              }
          }
        },
        {
          label: '数据链接',
          prop: "dataBase",
          dicData: this.dataLink,
          props: {
            label: "dbName",
            value: "id"
          },
          clearable:false,
          span: 4,
          type: 'select',
          placeholder: '请选择数据链接',
          change: (val)=>{
            this.$set(this.$refs.gridHeadLayout.searchForm,'title','')
            this.dataBase = val.value
            this.onLoad(val.value)
          }
        },
      ]
    },
    tableOptions() {
      return {
        index: true,
        indexLabel: '序号',
        menuWidth: 200,
        column: [
          {
            label: '表名',
            prop: "table_name",
            width: '250'
          },
          {
            label: '说明',
            prop: "table_comment",
          },
        ]
      }
    },
    gridRowBtn() {
      return [
        {
          label: this.$t('cip.cmn.btn.editBtn'),
          emit: "row-edit",
          type: "button",
        },
        {
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "row-remove",
          type: "button",
        },
        {
          label: '打开数据',
          emit: "row-open",
          type: "button",
        }
      ]
    }
  },
  mounted() {
    this.getDataLinkList();
  },
  methods: {
    //新增
    headAdd() {
      this.formVisible = true;
      this.$nextTick(() => {
        this.$refs.Form.init(this.dataBase, '')
      })
    },
    //编辑
    rowEdit(row) {
      this.formVisible = true
      this.$nextTick(() => {
        this.$refs.Form.init(this.dataBase, row)
      })
    },
    //删除
    rowRemove(row) {
      deleteTable(this.dataBase, {
        tableNames: row.table_name
      }).then((res) => {
        this.$message({
          message: res.data.msg,
          type: 'success',
          duration: 1500,
          onClose: () => {
            this.gridHeadEmpty();
          }
        })
      })
    },
    //打开数据
    rowOpen(row) {
      this.showData = true
      this.$nextTick(() => {
        this.$refs.preview.init(this.dataBase, row)
      })
    },
    //关闭表单弹窗
    closeForm() {
      this.formVisible = false;
      this.gridHeadEmpty();
    },
    //关闭数据弹窗
    closeData() {
      this.showData = false;
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    //多选删除
    handleDelete() {
      if (this.table_name == '') {
        this.$message({
          message: '请选择要删除的数据',
          type: 'error',
          duration: 1500,
        })
        return;
      }
      deleteTable(this.dataBase, {
        tableNames: this.table_name
      }).then((res) => {
        this.$message({
          message: res.data.msg,
          type: 'success',
          duration: 1500,
          onClose: () => {
            this.gridHeadEmpty();
          }
        })
      })
    },
    //置空查询条件
    gridHeadEmpty() {
      this.$set(this.$refs.gridHeadLayout.searchForm,'title','')
      this.$refs.gridHeadLayout.searchForm.dataBase = this.dataBase;
      this.onLoad(this.dataBase);
    },
    //根据条件查询
    gridHeadSearch() {
      this.$set(this.$refs.gridHeadLayout.searchForm,'title','')
      this.onLoad(this.dataBase)
    },
    // 获取数据库表列表
    onLoad(linkId) {
      this.tableLoading = true;
      dataModelList(linkId).then((res) => {
        if (res.data.code == 200) {
          this.tableData = res.data.data;
          this.tableDataBak = res.data.data;
          this.searchColumns.title = ''
        }
      }).finally(()=>{
        this.tableLoading = false;
      })
    },
    // 选择数据连接数据
    getDataLinkList() {
      dataLinkList().then((res) => {
        this.$nextTick(() => {
          this.dataLink = res.data.data;
          this.dataBase = res.data.data[0].id;
          this.$refs.gridHeadLayout.searchForm.dataBase = this.dataBase;
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.content {
  position: relative;
}

::v-deep .avue-crud .el-table {
  height: calc(100vh - 240px) !important;
  max-height: calc(100vh - 240px) !important;
}
</style>
